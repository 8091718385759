import React, { createContext, useContext, useMemo } from 'react';
import { ProjectSkillsService } from '@just-ai/api/dist/services/ProjectSkillsService';
import { ProjectService } from '@just-ai/api/dist/services/ProjectService';
import { IntegrationsService } from '@just-ai/api/dist/services/IntegrationsService';

import { axios } from 'pipes/functions';
import { useAppSelector } from 'storeHooks';

export type AppContextType = {
  projectSkillsService: ProjectSkillsService;
  botProjectService: ProjectService;
  integrationsService: IntegrationsService;
  currentUser: any;
  currentProject: any;
  language: string;
  accountId: number;
};
export const AppContext = createContext<AppContextType>({} as AppContextType);

type AppContextProviderType = {
  children: React.ReactChildren;
};
export const AppContextProvider = ({ children }: AppContextProviderType) => {
  const { currentUser, currentProject, language } = useAppSelector(store => {
    return {
      currentUser: store.CurrentUserReducer.currentUser,
      currentProject: store.CurrentProjectsReducer.currentProject,
      language: store.CurrentUserReducer.language,
    };
  });
  const currentUserId = currentUser?.account.id || -1;
  return (
    <AppContext.Provider
      value={{
        currentUser,
        currentProject,
        language,
        projectSkillsService: useMemo(() => new ProjectSkillsService(currentUserId, axios), [currentUserId]),
        botProjectService: useMemo(() => new ProjectService(currentUserId, axios), [currentUserId]),
        integrationsService: useMemo(
          () => new IntegrationsService(currentUser.account.id as number, currentProject),
          [currentProject, currentUser?.account?.id]
        ),
        accountId: currentUser?.account.id,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
