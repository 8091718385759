import { useCallback, useState } from 'react';

export const useProjectNameHook = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const setNameHandler = useCallback(name => {
    setName(name?.trimStart());
    setNameError(prevState => {
      if (prevState) return !(name?.trimStart().length > 0);
      return prevState;
    });
  }, []);

  return {
    setNameHandler,
    setNameError,
    nameError,
    name,
    trimmedName: name?.trim(),
  };
};
