import React, { useState, useEffect, useCallback } from 'react';
import classes from './NewHeader.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

//@ts-ignore
import { t } from 'localization/index';
import Avatar from './Avatar';
//@ts-ignore
import { Modal } from 'altreidsds';
import mobileClasses from './MobileHeader.module.scss';
import { isIncluded } from '../../../isAccessFunction';

//@ts-ignore
import { AtreidesIcon } from 'altreidsds/dist/build';
import { RCurrentUser } from '../../../types';
import { useAppSelector } from 'storeHooks';

function PersonalInfo({
  dataTestId,
  email,
  logout,
  isPartner,
  avatar,
  isMobile,
  tariff,
  addSnackbar,
  login,
}: {
  dataTestId: string;
  email: string;
  logout: () => void;
  language: string;
  isPartner: boolean;
  avatar?: string;
  isMobile?: boolean;
  tariff?: RCurrentUser['currentUser']['tariff'];
  addSnackbar: (text: string) => void;
  login: string;
}) {
  const { cloudDomains } = useAppSelector(store => ({
    cloudDomains: store.AppConfigReducer.cloudDomains,
  }));
  const [showModal, setShowModal] = useState(false);
  const [blockPopupOnAppear, setBlockPopupOnAppear] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setBlockPopupOnAppear(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const copyEmail = useCallback(() => {
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(email ? email : login).then(function () {
        addSnackbar(t('Header: email copied'));
      });
    }
  }, [addSnackbar, email, login]);

  const personalInfo = (
    <>
      <div className={classNames(classes.boldText, classes.email, classes.link)} data-test-id={dataTestId}>
        {email ? (
          <>
            <span title={email}>{email?.split('@')[0]}</span>
            <span title={email}>@</span>
            <span title={email}>{email?.split('@')[1]}</span>
          </>
        ) : (
          <span title={login}>{login}</span>
        )}
        <button className={classes.copyButton} onClick={copyEmail}>
          <AtreidesIcon name='copy' color='default' size='middle' />
        </button>
      </div>
      <div className={classes.delimiter} />
      {isIncluded(['zenflow_billing_feature']) && (
        <NavLink className={classes.link} to='/personal?tab=0'>
          {t('Tariff management')}
        </NavLink>
      )}
      {isIncluded(['zenflow_billing_feature']) && (
        <NavLink className={classes.link} to='/personal?tab=1'>
          {t('Payments')}
        </NavLink>
      )}
      <a className={classes.link} href={`${window.location.protocol}//${cloudDomains.cc.domain}/c/profile`}>
        {t('Header: account')}
      </a>
      {isIncluded(['zenflow_telephony_feature']) && (
        <NavLink className={classes.link} to='/personal?tab=4'>
          {t('TelephonySettings title')}
        </NavLink>
      )}
      <div className={classes.delimiter} />
      <button onClick={logout} className={classes.link}>
        {t('Sign out')}
      </button>
    </>
  );

  return (
    <div className={classNames(classes.personalContainer, isMobile && classes.mobilePersonalContainer)}>
      {isMobile ? (
        <button className={classes.showButton} onClick={() => setShowModal(true)}>
          <Avatar avatar={avatar} />
        </button>
      ) : (
        <Avatar avatar={avatar} />
      )}
      {isMobile ? (
        <Modal
          open={showModal}
          className={mobileClasses.popoverContainer}
          classes={{
            modal: mobileClasses.modal,
            modalContent: classNames(
              mobileClasses.modalContent,
              mobileClasses.tariffs,
              classes.personalPopup,
              classes.mobilePopup
            ),
            modalIconClose: mobileClasses.modalIconClose,
          }}
          onClose={() => setShowModal(false)}
        >
          {personalInfo}
        </Modal>
      ) : (
        <div className={classNames(classes.personalPopup, classes.popup, blockPopupOnAppear && classes.blocked)}>
          {personalInfo}
        </div>
      )}
    </div>
  );
}

export default React.memo(PersonalInfo);
