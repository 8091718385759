import React from 'react';
import localize from 'localization';
import { InputText } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import Groups from '../Groups';
import { axios } from '../../../../../pipes/functions';
import forEach from 'lodash/forEach';
import { AxiosError } from 'axios';

const { translate: t } = localize;

export default class LivetexEditForm extends BaseEditForm {
  constructor(props) {
    super(props);
    this.form = {};
    this.timeoutIdStatusUpdate = null;
    this.timeoutIdGetDestinations = null;

    this.defaultFields = {};

    this.state = {
      apiKey: this.props.editableChannel.config?.apiKey || '',
      hasError: false,
      errorTextApiKey: '',
      wasChanged: false,
      errors: [],
      destinations: [],
    };

    this.destinationsValues = [];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.apiKey !== this.state.apiKey) {
      if (this.timeoutIdGetDestinations) {
        clearTimeout(this.timeoutIdGetDestinations);
      }
      this.timeoutIdGetDestinations = setTimeout(this.getDestinations, 1000);
    }
  }

  delayedStateUpdate = value => {
    const setValueApiKey = () => {
      this.setState({ apiKey: value });
    };

    if (this.timeoutIdStatusUpdate) {
      clearTimeout(this.timeoutIdStatusUpdate);
    }
    this.timeoutIdStatusUpdate = setTimeout(setValueApiKey, 500);
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.editableChannel?.config?.apiKey) {
        this.destinationsValues = this.props.editableChannel?.config?.destinations || [];
        this.getDestinations();
      }
    }, 0);
  }

  submit = async e => {
    e.preventDefault();
    e.stopPropagation();

    const { apiKey, errorTextApiKey } = this.state;

    if (errorTextApiKey) return;

    if (apiKey === '') {
      this.setState({ errorTextApiKey: t('Required field') });
      return;
    }

    this.setState({ fetching: true });

    if (Boolean(this.props.editableChannel.id)) {
      await this.saveChannel();
    } else {
      await this.createChannel();
    }

    this.setState({ fetching: false });
  };

  createChannel = async () => {
    const { createChannel } = this.props;

    const channelData = {
      type: this.props.editableChannel.channelType,
      description: this.form.description.value,
      config: {
        apiKey: this.form.apiKey.value,
        destinations: this.destinationsValues,
      },
    };

    let errorFlag = false;
    if (channelData?.config?.destinations[0]) {
      forEach(channelData.config.destinations, destination => {
        if (!destination.ids || destination.ids.length === 0) {
          errorFlag = true;
        }
      });
    }

    if (errorFlag) return this.setState({ hasErrors: true });

    await createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    const { editChannel } = this.props;

    const channelData = {
      ...this.props.editableChannel,
      config: {
        apiKey: this.form.apiKey.value,
        destinations: this.destinationsValues,
      },
    };

    let errorFlag = false;
    if (channelData?.config?.destinations[0]) {
      forEach(channelData.config.destinations, destination => {
        if (!destination.ids || destination.ids.length === 0) {
          errorFlag = true;
        }
      });
    }

    if (errorFlag) return this.setState({ errors: [t('Check fields value')] });

    await editChannel(channelData);
    this.props.onClose();
  };

  validate = value => {
    this.delayedStateUpdate(value);

    if (value.length === 0) {
      this.setState({ errorTextApiKey: t('Required field') });
    }
  };

  checkIsFetching = () => {
    const { fetching } = this.props;
    const { hasError, fetching: localFetching } = this.state;
    return fetching || hasError || localFetching;
  };

  addDestination = () => this.props.addDestination();

  changeDestination = (key, property, value) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};

    this.destinationsValues[key][property] = value;
  };

  addIds = (key, ids) => {
    if (!this.destinationsValues[key]) this.destinationsValues[key] = {};
    this.destinationsValues[key].ids = ids;
  };

  getDestinations = async () => {
    if (this.state.apiKey === '') return;

    try {
      await axios.get(`/restapi/channels/livetex/getDestinations?key=${this.state.apiKey}`).then(data => {
        if (data.data === '') {
          this.setState({ errorTextApiKey: t('Incorrect token') });
        }
        if (data.data?.length > 0) {
          this.setState({ destinations: data.data.filter(Boolean), errorTextApiKey: '' });
        }
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        this.setState({ errorTextApiKey: t('Incorrect token') });
      }
    }
  };

  removeGroup = k => {
    this.props.removeGroup(k);
    this.destinationsValues.splice(k, 1);
  };

  renderFooter = () => {
    const { destinations } = this.state;
    return (
      <Groups
        editableChannel={this.props.editableChannel}
        destinations={destinations}
        removeGroup={this.removeGroup}
        changeDestination={this.changeDestination}
        addIds={this.addIds}
        addDestination={this.addDestination}
        selectIDS={this.props.selectIDS}
      />
    );
  };

  renderInputs() {
    const { editableChannel } = this.props;
    const { errorTextApiKey } = this.state;

    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Name')}</p>
          <InputText
            innerRef={input => (this.form.description = input)}
            id='description'
            defaultValue={
              editableChannel.id ? editableChannel.description : t(`AddChannelCard ${editableChannel.channelType}`)
            }
            placeholder={t('Enter custom', t('Name').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Access key')}</p>
          <InputText
            id='access_key'
            errorText={errorTextApiKey}
            defaultValue={editableChannel?.config?.apiKey}
            innerRef={input => (this.form.apiKey = input)}
            onChange={value => this.validate(value)}
            placeholder={t('Enter custom', t('Access key').toLowerCase())}
          />
        </div>
      </>
    );
  }
}
