import { isObject, mapKeys, mergeWith } from 'lodash';
import { visualeditorLocalization } from './visualeditor.loc';
import { isEuroInstance } from '../../isAccessFunction';

const Localize = require('localize');
require('decliner/dist/decliner.min');

const localize = new Localize({ ...visualeditorLocalization });

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

const defaultHelpUrlsPath = {
  text: {
    eng: '/how-to-create-a-script/bot-response/text',
    ru: '/how-to-create-a-script/bot-response/text',
    pt: '/how-to-create-a-script/bot-response/text',
  },
  imageBlock: {
    eng: '/how-to-create-a-script/bot-response/image',
    ru: '/how-to-create-a-script/bot-response/image',
  },
  video: {
    eng: '/how-to-create-a-script/bot-response/video',
    ru: '/how-to-create-a-script/bot-response/video',
  },
  conditions: {
    eng: '/how-to-create-a-script/actions/conditions',
    ru: '/how-to-create-a-script/actions/conditions',
    pt: '/how-to-create-a-script/actions/conditions',
  },
  textInput: {
    eng: '/how-to-create-a-script/actions/text_entry',
    ru: '/how-to-create-a-script/actions/text_entry',
  },
  numberInput: {
    eng: '/how-to-create-a-script/actions/number_entry',
    ru: '/how-to-create-a-script/actions/number_entry',
  },
  fileInput: {
    eng: '/how-to-create-a-script/actions/file_upload',
    ru: '/how-to-create-a-script/actions/file_upload',
  },
  phoneNumber: {
    eng: '/how-to-create-a-script/actions/phone_number',
    ru: '/how-to-create-a-script/actions/phone_number',
  },
  transferToOperator: {
    eng: '/how-to-create-a-script/actions/to_operator',
    ru: '/how-to-create-a-script/actions/to_operator',
    pt: '/how-to-create-a-script/actions/to_operator',
  },
  intents: {
    eng: '/how-to-create-a-script/user-says/intent',
    ru: '/how-to-create-a-script/user-says/intent',
    pt: '/how-to-create-a-script/user-says/intent',
  },
  httpRequest: {
    eng: '/how-to-create-a-script/actions/http_request',
    ru: '/how-to-create-a-script/actions/http_request',
    pt: '/how-to-create-a-script/actions/http_request',
  },
  email: {
    eng: '/how-to-create-a-script/actions/email',
    ru: '/how-to-create-a-script/actions/email',
  },
  transition: {
    eng: '/how-to-create-a-script/actions/transition',
    ru: '/how-to-create-a-script/actions/transition',
  },
  endSession: {
    eng: '/how-to-create-a-script/actions/scenario_end',
    ru: '/how-to-create-a-script/actions/scenario_end',
  },
  audios: {
    eng: '/how-to-create-a-script/bot-response/audio',
    ru: '/how-to-create-a-script/bot-response/audio',
  },
  timeout: {
    eng: '/how-to-create-a-script/actions/timeout',
    ru: '/how-to-create-a-script/actions/timeout',
  },
  chooseReadyIntent: {
    eng: '/how-to-create-a-script/user-says/premade_intents',
    ru: '/how-to-create-a-script/user-says/premade_intents',
    pt: '/how-to-create-a-script/user-says/premade_intents',
  },
  transferToCallOperator: {
    eng: '/how-to-create-a-script/actions/to_operator_calls',
    ru: '/how-to-create-a-script/actions/to_operator_calls',
    pt: '/how-to-create-a-script/actions/to_operator_calls',
  },
  syntez: {
    eng: '/how-to-create-a-script/bot-response/speech_synthesis',
    ru: '/how-to-create-a-script/bot-response/speech_synthesis',
  },
  'Synthesis:Instruction:Link': {
    eng: '/advanced/speech_synthesis_markup',
    ru: '/advanced/speech_synthesis_markup',
    pt: '/advanced/speech_synthesis_markup',
  },
  Sms: {
    eng: '/how-to-create-a-script/actions/sms',
    ru: '/how-to-create-a-script/actions/sms',
  },
  GoogleSheets: {
    eng: '/how-to-create-a-script/actions/google_sheets',
    ru: '/how-to-create-a-script/actions/google_sheets',
    pt: '/how-to-create-a-script/actions/google_sheets',
  },
  Timetable: {
    eng: '/how-to-create-a-script/actions/schedule',
    ru: '/how-to-create-a-script/actions/schedule',
  },
  TelegramPayment: {
    eng: '/how-to-create-a-script/actions/telegram_payments',
    ru: '/how-to-create-a-script/actions/telegram_payments',
  },
  script: {
    eng: '/how-to-create-a-script/bot-response/code',
    ru: '/how-to-create-a-script/bot-response/code',
    pt: '/how-to-create-a-script/bot-response/code',
  },
  audiosModal: {
    eng: '/channels/restrictions',
    ru: '/channels/restrictions',
  },
  CrmIntegration: {
    eng: '/how-to-create-a-script/actions/crm_integration',
    ru: '/how-to-create-a-script/actions/crm_integration',
  },
};

function hasAllTranslates(obj) {
  return obj.hasOwnProperty('ru') && Boolean(obj.ru) && obj.hasOwnProperty('eng') && Boolean(obj.eng);
}

localize.setAppConfigVariables = zenflowAppConfig => {
  localize.appConfigVariables = {
    productName: Boolean(zenflowAppConfig.productName) ? zenflowAppConfig.productName : 'Aimylogic',
    companyName: Boolean(zenflowAppConfig.companyName) ? zenflowAppConfig.companyName : 'Aimylogic',
    helpUrl:
      Boolean(zenflowAppConfig.helpUrl) &&
      isObject(zenflowAppConfig.helpUrl) &&
      hasAllTranslates(zenflowAppConfig.helpUrl)
        ? zenflowAppConfig.helpUrl
        : {
            eng: 'https://help.cloud.just-ai.com/en/aimylogic',
            ru: 'https://help.cloud.just-ai.com/aimylogic',
            cn: 'https://help.cloud.just-ai.com/en/aimylogic',
          },
    helpUrlsPath: Boolean(zenflowAppConfig.helpUrlsPath)
      ? { ...defaultHelpUrlsPath, ...zenflowAppConfig.helpUrlsPath }
      : defaultHelpUrlsPath,
  };

  localize.setConfigVariables({
    zenflow: { helpUrl: localize.appConfigVariables.helpUrl },
  });

  localize.addTranslations({
    'Text link': getUrlPath('text'),
    'Image block link': getUrlPath('imageBlock'),
    'Conditions link': getUrlPath('conditions'),
    'Text input link': getUrlPath('textInput'),
    'Number input link': getUrlPath('numberInput'),
    'File input link': getUrlPath('fileInput'),
    'Phone number link': getUrlPath('phoneNumber'),
    'Transfer to operator link': getUrlPath('transferToOperator'),
    'Intents link': getUrlPath('intents'),
    'Http request link': getUrlPath('httpRequest'),
    'Http email link': getUrlPath('email'),
    'Transition link': getUrlPath('transition'),
    'End session link': getUrlPath('endSession'),
    'AddingMenu CrmIntegration link': getUrlPath('CrmIntegration'),
    'AddingMenu timeout link': getUrlPath('timeout'),
    'helpLink audios': getUrlPath('audios'),
    'helpLink timeout': getUrlPath('timeout'),
    'AudiosEditModal subtext 3 documents link': getUrlPath('audios'),
    'ChooseReadyIntent spravka link': getUrlPath('chooseReadyIntent'),
    'helpLink image': getUrlPath('imageBlock'),
    'helpLink answer': getUrlPath('text'),
    'helpLink syntez': getUrlPath('syntez'),
    'helpLink text input': getUrlPath('textInput'),
    'helpLink number input': getUrlPath('numberInput'),
    'helpLink File input link': getUrlPath('fileInput'),
    'helpLink phone input': getUrlPath('phoneNumber'),
    'helpLink intents': getUrlPath('intents'),
    'helpLink conditions': getUrlPath('conditions'),
    'helpLink http request': getUrlPath('httpRequest'),
    'helpLink transfer to operator': getUrlPath('transferToOperator'),
    'helpLink video': getUrlPath('video'),
    'helpLink http email': getUrlPath('email'),
    'helpLink Sms': getUrlPath('Sms'),
    'helpLink GoogleSheets': getUrlPath('GoogleSheets'),
    'helpLink Timetable': getUrlPath('Timetable'),
    'helpLink TelegramPayment': getUrlPath('TelegramPayment'),
    'helpLink script': getUrlPath('script'),
    'helpLink transferToCallOperator': getUrlPath('transferToCallOperator'),
    'AnswerModal:info_link': getUrlPath('audiosModal'),
    'Synthesis:Instruction:Link': getUrlPath('Synthesis:Instruction:Link'),
  });
};

const getUrlPath = key => {
  if (
    Boolean(localize.appConfigVariables) &&
    Boolean(localize.appConfigVariables.helpUrl) &&
    Boolean(localize.appConfigVariables.helpUrlsPath)
  ) {
    return localize.appConfigVariables.helpUrlsPath.hasOwnProperty(key)
      ? mergeWith(
          { ...localize.appConfigVariables.helpUrl },
          localize.appConfigVariables.helpUrlsPath[key],
          (url, path) => {
            return (isEuroInstance() ? 'https://help.cloud.tovie.ai/ds/' : url) + path;
          }
        )
      : localize.appConfigVariables.helpUrl;
  } else {
    return {
      eng: '',
      ru: '',
    };
  }
};

localize.throwOnMissingTranslation(false);
export default localize;
export const { translate: tVis } = localize;
export const { translate: t } = localize;
