const drawerWidth = 260;
const drawerPaperCloseWidth = 82;

const styles = theme => ({
  bannerCustomClass: {
    width: '100%',
    left: 0,
    right: 0,
    zIndex: '1000',
    '& * ': {
      letterSpacing: 'normal',
    },
  },
  mobileBannerCustomClass: {
    width: '100%',
    position: 'initial',
    top: 0,
    left: 0,
    zIndex: '1202',
    padding: '10px 8px 10px 10px',
    '& * ': {
      letterSpacing: 'normal',
    },
    '& > div': {
      '& > div': {
        '& > div': {
          '& > div': {
            '& > div': {
              flexWrap: 'nowrap',
              alignItems: 'flex-start',
            },
          },
        },
      },
    },
  },
  noMargin: {
    '&.p': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  mainContent: {
    gridArea: 'main',
    backgroundColor: 'var(--blue-100)',
    height: '100%',
    '& > div:first-child': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
    },
  },
  appFrame: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    flexDirection: 'column',
    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
  content: {
    position: 'relative',
    width: `100%`,
    flexGrow: 1,
    backgroundColor: 'var(--blue-100)',
    padding: '24px 24px 4rem',
    height: '100%',
    minHeight: '100%',
    '&.fullSizePage': {
      width: `100%`,
      marginLeft: 0,
    },
  },
  mobileContent: {
    position: 'relative',
    width: `100vw`,
    maxWidth: '100vw',
    flexGrow: 1,
    backgroundColor: 'var(--blue-100)',
    marginTop: 0,
    marginLeft: `0px`,
    marginBottom: '40px',
    overflow: 'hidden',
  },
  contentShift: {
    marginLeft: `${drawerWidth}px`,
  },

  withoutPadding: {
    padding: `0px !important`,
    paddingBottom: `4rem !important`,
    marginLeft: '0px !important',
    width: '100vw !important',
  },

  withoutPaddingNormalWidth: {
    padding: `0px !important`,
    paddingBottom: `4rem !important`,
    marginLeft: '0px !important',
  },

  faqContainer: {
    padding: `0px !important`,
    marginLeft: '0px !important',
    height: '100%',
    '& div.question-phrases-list': {
      marginRight: '-5px',
    },
  },

  fullHeight: {
    '& > div:first-child': {
      height: '100%',
    },
  },

  withoutBottomPaddingToo: {
    padding: `0px !important`,
  },
  snackbar: {
    position: 'fixed',
    left: `${drawerPaperCloseWidth}px`,
    bottom: '50px',
  },
  snackbarShift: {
    left: `${drawerWidth}px`,
  },
  returnToMobileButton: {
    position: 'fixed',
    width: '100vw',
    zIndex: 1000,
    bottom: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  },
});

export default styles;
